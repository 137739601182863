html {
  overflow-y: scroll;
}

.container-fluid {
  max-width: 1500px;
  height: 100%;
  padding: 0;
}

.nav-link {
  font-size: 1.1rem;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .container-fluid {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .nav-link:last-child {
    padding-bottom: 1rem;
  }
}

.row img {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.map {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 575px) {
  .map {
    max-width: 1100px;
  }
}

.grid-wrapper {
  width: 100%;
  text-align: left;
}

.filter-text-box {
  display: inline;
  float: right;
  margin-left: auto;
  width: 20rem;
  max-width: 50%;
  height: 1.5rem;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 3px;
  border-radius: 0.25rem;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  color: black;
  font-family: inherit;
  padding: 20px 12px;
  box-sizing: border-box;
}

.table-link {
  color: black;
}

.ag-theme-alpine {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ag-header-cell {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.ag-header-group-cell {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.alternate-row {
  background-color: rgba(109, 109, 109, 0.123) !important;
}

.ag-root-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.loading-wrapper {
  display: flex;
  margin-top: 20%;
  justify-content: center;
  align-items: center;
  height: inherit;
}

path {
  cursor: pointer;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: #1a2b47 !important;
  color: white !important;
}

.btn-primary:hover {
  background-color: #121f33 !important;
}
